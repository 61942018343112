import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import DateFnsUtils from '@date-io/date-fns';
import { MuiThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ErrorBoundary } from 'react-error-boundary';
import TagManager from 'react-gtm-module';

import { ErrorFallback } from '../shared/components/error-fallback';
import store from '../store';
import { Routes } from './Routes';

import { THEME } from '../styles/theme';
import useCountry from '../shared/hooks/useCountry';

const App = () => {
  useCountry();
  useEffect(() => {
    if (process.env.REACT_APP_GTM_ID && !window.location.hostname.includes('demo')) {
      TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });
    }
  }, []);

  return (
    <ErrorBoundary fallback={<ErrorFallback />}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_AUTH0_IDENTIFY_ID as string}>
        <Provider store={store}>
          <MuiThemeProvider theme={THEME}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Router>
                <Routes />
              </Router>
            </MuiPickersUtilsProvider>
          </MuiThemeProvider>
        </Provider>
      </GoogleOAuthProvider>
    </ErrorBoundary>
  );
};

export default App;
