import { useState, useEffect } from 'react';

interface CountryData {
  country: string;
}

const useCountry = () => {
  const [country, setCountry] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await fetch('https://ipinfo.io/json?token=1bbb20a86bb543');
        if (!response.ok) {
          throw new Error('Failed to fetch country');
        }
        const data: CountryData = await response.json();
        setCountry(data.country);
        localStorage.setItem('user_country', data.country);
      } catch (err) {
        setError((err as Error).message);
      } finally {
        setLoading(false);
      }
    };

    fetchCountry();
  }, []);

  return { country, loading, error };
};

export default useCountry;
