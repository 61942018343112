import { MethodType } from '../../components/AccountDrawer/Billing/PaymentMethods';
import { DiscountPeriod } from '../admin-discounts/types';
import { Address, BaseCompany, Contact, DeliveryDetailsInUser } from '../company/types';
import { CustomerConnectionStatus } from '../customer/types';
import { PaginationResult, SuccessResponse } from '../index';
import { FinishOrderOption } from '../order/types';
import { PriceList } from '../price-list/types';
import { Category } from '../product/types';
import { CustomPermission, TeammatePermission } from '../teammates/types';

export interface BaseUser {
  id: number;
  email: string;
  abn_number: string | null;
  code: string | null;
  first_name: string;
  last_name: string;
  full_name: string;
  picture_url: string;
  phone_number: string | null;
  slug: string;
  created_at: string;
  company_addresses?: Address[];
  on_boarded: boolean;
  teammate_onboarded: boolean;
  subscription_type: SubscriptionPlanEnum;
  is_admin: boolean;
  is_sa: boolean;
  is_teammate: boolean;
  account_email?: string;
  verified: boolean;
  profit_margin: boolean;
  auto_verify: boolean;
  email_verified: boolean;
  demo_exited: boolean;
  hidden_column?: HiddenColumn | null;
  supplier_blocked?: boolean;
  onboarding_tooltips_shown?: boolean;
  show_prices: boolean;
  holidays_delivery: boolean;
  daily_order_mail: boolean;
  holidays?: HolidayDate[];
  deleted_at: string | null;
  autofill_once?: boolean;
  pos_set_up?: boolean;
  auto_pos_fetch?: boolean;
  country_id: number | null;
  last_sync_details?: null | {
    id: number;
    created_at: string;
    sales_count: number;
    sales_total: string;
    updated_at: string;
    user_id: number;
    waste_count: number;
  };
  pos_sync_time?: string | null;
  country_data_for?: string;
  sign_up_status?: 'menu_costing' | 'restaurent_pro_suite';
  payment_method_type?: MethodType | null;
  permission: TeammatePermission | null;
  custom_permission?: CustomPermission | null;
  restaurant_onboarding_level?: 'Setup profile' | 'Add suppliers' | 'complete';
  same_product_popup: boolean;
  cut_off_time_notifications: boolean;
  inventory_trigger_notifications: boolean;
}

export interface SupplierUser extends BaseUser {
  company: BaseCompany;
  delivery_detail: DeliveryDetailsInUser;
  next_day_delivery_day: string;
  delivery_radiuses: DeliveryRadius[];
  categories: Category[];
  delivery_windows: DeliveryWindows[];
  company_addresses: Address[];
  company_contacts?: Contact[];
  finish_type: FinishOrderOption;
  company_id: number;
  stripe_user_id: string | null;
  delivery_fee_by_day: {
    id: number;
    [key: string]: number;
  };
  manual_supplier: boolean;
  locked_supplier: boolean;
  email_set: boolean;
  display_stock: boolean;
  added_by: number | null;
}

export interface ConnectionRequest {
  id: number;
  status: CustomerConnectionStatus;
  company_name: string | null;
  company_logo: string | null;
  sales_to_date: number;
  last_order_date: string;
  price_list: PriceList | null;
  user_price_list: Partial<PriceList> | null;
  connection_detail: ConnectionDetail | null;
  customer_id: string | null;
  send_by: Roles;
  user: BaseUser;
  company_addresses: Address[];
  supplier: SupplierUser;
  delivery_number: string | null;
  connection_categories: {
    category_id: number;
    connection_request_id: number;
    id: number;
  }[];
  categories: Category[];
  restaurant_company: {
    id: number;
    name: string | null;
    picture_url: string | null;
  };
  supplier_company: {
    id: number;
    name: string | null;
    picture_url: string | null;
  };
  minimum_delivery_apply: boolean;
}

export interface CustomerListResponse extends PaginationResult {
  customers: ConnectionRequest[];
}

export enum CustomerPaymentMethod {
  account = 'on_account',
  card = 'credit_card',
  none = 'selected_none',
}

export interface ConnectionDetail {
  id: number;
  charge_delivery: boolean;
  payment_method: CustomerPaymentMethod;
  stop_credit: boolean;
  supplier_id: number;
  user_id: number;
  created_at: string;
}

export enum AutoCheckOffEnum {
  h6 = 'after_6_hour',
  h12 = 'after_12_hour',
  h24 = 'after_24_hour',
  h36 = 'after_36_hour',
  h48 = 'after_48_hour',
}

export enum AutoCheckOffReqEnum {
  h6 = 0,
  h12 = 1,
  h24 = 2,
  h36 = 3,
  h48 = 4,
}

export interface RestaurantUser extends BaseUser {
  company: BaseCompany;
  sub_recipe_video_viewed: boolean | null;
  delivery_windows: DeliveryWindows[];
  menu_costing_onboarding?: string[];
  pro_suite_onboarding?: RPSOnboardingSteps[];
  stripe_id: string | null;
  auto_check_off: AutoCheckOffEnum | null;
  incomplete_setup_self_managed_suppliers: {
    supplier_count: number;
    suppliers_data: { name: string; order_email: string; supplier_id: number }[];
  };
}

export enum RPSOnboardingSteps {
  INVITE = 'Invite your suppliers',
  MC = 'Set up menu costing',
  INVENTORY = 'Set up inventory & stock',
  AUTOMATED = 'Try automated ordering',
  ORDER = 'Start placing orders',
}

export interface UpdatePasswordRequest {
  user: {
    current_password: string;
    password: string;
    password_confirmation: string;
  };
}

export interface ContactMsgRequest {
  user: {
    help_subject: string;
    help_message: string;
    first_name?: string;
    last_name?: string;
    email?: string;
    phone_number?: string;
  };
}

export enum Roles {
  CUSTOMER = 'restaurant',
  SUPPLIER = 'supplier',
}

export interface VenueResponse {
  addresses: {
    id: number;
    addressable_id: number;
    addressable_type: string;
    address_type: null | string;
    street_address1: null | string;
    street_address2: null | string;
    apt_number: null | string;
    city: string;
    state: string;
    postcode: string;
    country: string;
    latitude: null | string;
    longitude: null | string;
    full_address: string;
    created_at: string;
    updated_at: string;
    first_name: null | string;
    last_name: null | string;
    default_address: boolean;
  }[];
}

export interface UpdateDeliveryWindows {
  user: {
    delivery_windows_attributes: {
      id?: number;
      day: string;
      from_time: string;
      to_time: string;
    }[];
  };
}

export interface DeliveryWindows {
  id?: number;
  day: string;
  from_time: string;
  to_time: string;
}

export interface DeliveryRadius {
  id: number;
  delivery_radius: number;
  supplier_id: number;
  full_address: string | null;
  latitude: number | null;
  longitude: number | null;
}

export interface CreateAddressRequest {
  address: {
    id?: number;
    first_name?: string;
    city?: string;
    country?: string;
    full_address?: string;
    postcode?: string;
    state?: string;
    street_address1?: string;
    street_address2?: string;
    default_address?: boolean;
  };
}

export interface UpdateAddressRequest extends CreateAddressRequest {
  addressId: number;
}

export interface SubCategory {
  id: number;
  name: string;
  supplier_id: number;
}

export interface GetSubCategoriesResponse {
  sub_category: SubCategory[];
}

export interface CreateSubCategoryResponse extends SuccessResponse {
  sub_category: SubCategory;
}

export interface NotificationSettingResponse extends SuccessResponse {
  notification_setting: NotificationSetting;
}

export interface UpdateNotificationSettingRequest {
  notification_setting: Partial<NotificationSetting>;
}

export interface NotificationSetting {
  new_orders: boolean;
  connection_requests: boolean;
  transactional_emails: boolean;
  promotional: boolean;
  order_receipts_for_rs: boolean;
  transactional_emails_for_rs: boolean;
  important_emails_for_rs: boolean;
  cut_off_time_notifications: boolean;
  inventory_trigger_notifications: boolean;
}

export interface VerifySupplierResponse extends SuccessResponse {
  supplier: SupplierUser;
}

export enum SubscriptionPlanEnum {
  FREE = 'free',
  UNLIMITED = 'unlimited',
  ENTERPRISE = 'enterprise',
  NORMAL = 'normal',
  MENU = 'menu_planning',
  HOSPO = 'hospo',
}

export interface UpdateAdminProfile {
  showToast: boolean;
  user: {
    first_name?: string;
    last_name?: string;
    email?: string;
  };
}

export interface ProductColumn {
  brand_column?: boolean;
  unit_of_measure_column?: boolean;
  stock_column?: boolean;
  unit_size_column?: boolean;
  location_column?: boolean;
  shelf_space_column?: boolean;
  sub_category_column?: boolean;
  product_code_column?: boolean;
  your_cost_column?: boolean;
  tax_column?: boolean;
}

export interface HiddenColumn {
  id: number;
  order_column?: {
    order_number?: boolean;
    total?: boolean;
    notes?: boolean;
    deliver_to?: boolean;
    delivery_number?: boolean;
  };
  product_column?: ProductColumn;
}

export interface UpdateHiddenColumnsReq {
  hidden_column: Omit<HiddenColumn, 'id'>;
}

export interface UpdateColumnsResponse extends SuccessResponse {
  supplier: SupplierUser;
}

export interface HolidayDate {
  id: number;
  holiday_date: string;
}

export interface PurchaseResponse extends SuccessResponse {
  client_secret: string | null;
}

export interface PurchaseRequest {
  payment_method: string;
  first_name: string;
  last_name: string;
  email: string;
  coupon_code?: string;
  country_code: string;
}

export interface VerifyPromoCodeRes extends SuccessResponse {
  percent_off: number | null;
  amount_off: number | null;
  discount_period: DiscountPeriod;
}
